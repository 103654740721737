import i18n, { i18n as I18n } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

type InitI18nInput = Readonly<{
  /**
   * Path where resources get loaded from
   */
  loadPath: string;
  /**
   * Logs info level to console output. Helps finding issues with loading not working.
   */
  debug: boolean;

  returnNull?: boolean;
}>;

export const initI18n = async ({ loadPath, debug, returnNull }: InitI18nInput): Promise<I18n> => {
  await i18n
    /**
     * Load translation using http -> see /public/locales
     * learn more: https://github.com/i18next/i18next-http-backend
     */
    .use(Backend)
    /**
     * Detect user language
     * learn more: https://github.com/i18next/i18next-browser-languageDetector
     */
    .use(LanguageDetector)
    // Pass the i18n instance to react-i18next.
    .use(initReactI18next)
    /**
     * Init i18next
     * for all options read: https://www.i18next.com/overview/configuration-options
     */
    .init({
      debug,
      fallbackLng: {
        nb: ["no", "en"],
        nn: ["no", "en"],
        default: ["en"],
      },
      load: "currentOnly",
      preload: ["en", "no"],
      interpolation: {
        // Not needed for react as it escapes by default
        escapeValue: false,
      },
      backend: {
        loadPath,
      },
      returnNull,
    });

  return i18n;
};
